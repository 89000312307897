.shine-editor {
  color: var(--primary-text-color);
  font-size: var(--editor-font-size);
  line-height: var(--editor-line-height);
  caret-color: var(--primary-color);
}

.shine-editor div,
.shine-editor table,
.shine-editor img,
.shine-editor pre,
.shine-editor li {
  margin-top: var(--paragraph-spacing);
  margin-bottom: var(--paragraph-spacing);
}

.shine-editor .shine-collapsible-section {
  margin-top: 5px;
  margin-bottom: 5px;
}

.shine-editor table div,
.shine-editor pre div {
  margin-top: unset;
  margin-bottom: unset;
}

.shine-editor h1 {
  font-size: 1.8em;
}

.shine-editor h1,
.shine-editor h2,
.shine-editor h3,
.shine-editor h4,
.shine-editor h5,
.shine-editor h6 {
  font-weight: var(--bold-font-weight);
  margin: 0.2em 0em;
}

.shine-editor b, .shine-editor strong {
  font-weight: 600;
}

.shine-editor img {
  background-color: var(--hover-color);
  min-height: 10px;
  border-radius: 6px;
}
.shine-editor img::selection {
  color: #474755;
  background-color: var(--hover-color);
  opacity: 1;
}
.shine-editor img[data-upnote-formula] {
  background-color: unset;
  min-height: unset;
  border-radius: 2px;
  filter: var(--svg-filter);
}
.shine-editor img[data-upnote-formula]::selection {
  background-color: var(--svg-hover-color);
  opacity: 1;
}
.shine-editor a {
  color: var(--primary-color);
}

.shine-editor ul li[data-checked="true"] {
  color: var(--checked-list-text-color);
  text-decoration: var(--checked-list-text-decoration);
}

.shine-editor li::marker {
  color: var(--primary-color);
}

.shine-editor hr {
  background-color: var(--divider-color);
}
.shine-editor blockquote {
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: var(--code-block-background-color);
  border-inline-start: var(--blockquote-border-color) 4px solid;
}

.shine-editor pre {
  background-color: var(--code-block-background-color);
  border: 1px solid var(--input-border-color);
}

.shine-editor code {
  color: var(--code-color);
  background-color: var(--code-background-color);
}

.shine-editor pre code {
  color: unset;
  background-color: unset;
}

/* Prevent empty code being collapsed with zero height */
.shine-editor div:empty::before,
.shine-editor code:empty::before {
  content: "\200b"; /* unicode zero width space character */
}

.shine-editor code * {
  font-family: monospace;
  font-size: calc(var(--editor-font-size) - 1px);
  width: max-content;
}

.shine-editor u span {
  text-decoration: underline;
}

.shine-editor strike span {
  text-decoration: line-through;
}

.shine-editor u strike span, .shine-editor strike u span {
  text-decoration: underline line-through;
}

.shine-editor .shine-text-red {
  color: var(--text-red);
  text-decoration-color: var(--text-red);
}

.shine-editor .shine-text-blue {
  color: var(--text-blue);
  text-decoration-color: var(--text-blue);
}

.shine-editor .shine-text-green {
  color: var(--text-green);
  text-decoration-color: var(--text-green);
}

.shine-editor .shine-text-yellow {
  color: var(--text-yellow);
  text-decoration-color: var(--text-yellow);
}

.shine-editor .shine-text-orange {
  color: var(--text-orange);
  text-decoration-color: var(--text-orange);
}

.shine-editor .shine-text-purple {
  color: var(--text-purple);
  text-decoration-color: var(--text-purple);
}

.shine-editor .shine-text-pink {
  color: var(--text-pink);
  text-decoration-color: var(--text-pink);
}

.shine-editor .shine-text-gray {
  color: var(--text-gray);
  text-decoration-color: var(--text-gray);
}

.shine-editor .shine-green-bg {
  background-color: var(--highlight-green);
}

.shine-editor .shine-red-bg {
  background-color: var(--highlight-red);
}

.shine-editor .shine-blue-bg {
  background-color: var(--highlight-blue);
}

.shine-editor .shine-yellow-bg {
  background-color: var(--highlight-yellow);
}

.shine-editor .shine-pink-bg {
  background-color: var(--highlight-pink);
}

.shine-editor .shine-purple-bg {
  background-color: var(--highlight-purple);
}

.shine-editor .shine-orange-bg {
  background-color: var(--highlight-orange);
}

.shine-editor .shine-gray-bg {
  background-color: var(--code-block-background-color);
}

.shine-editor .shine-highlight {
  background-color: var(--highlight-green);
  box-shadow: 0 2px 0 var(--highlight-green), 0 -2px 0 var(--highlight-green);
}

.shine-editor .shine-highlight-red {
  background-color: var(--highlight-red);
  box-shadow: 0 2px 0 var(--highlight-red), 0 -2px 0 var(--highlight-red);
}

.shine-editor .shine-highlight-orange {
  background-color: var(--highlight-orange);
  box-shadow: 0 2px 0 var(--highlight-orange), 0 -2px 0 var(--highlight-orange);
}

.shine-editor .shine-highlight-blue {
  background-color: var(--highlight-blue);
  box-shadow: 0 2px 0 var(--highlight-blue), 0 -2px 0 var(--highlight-blue);
}

.shine-editor .shine-highlight-yellow {
  background-color: var(--highlight-yellow);
  box-shadow: 0 2px 0 var(--highlight-yellow), 0 -2px 0 var(--highlight-yellow);
}

.shine-editor .shine-highlight-pink {
  background-color: var(--highlight-pink);
  box-shadow: 0 2px 0 var(--highlight-pink), 0 -2px 0 var(--highlight-pink);
}

.shine-editor .shine-highlight-purple {
  background-color: var(--highlight-purple);
  box-shadow: 0 2px 0 var(--highlight-purple), 0 -2px 0 var(--highlight-purple);
}

.shine-editor .shine-highlight-gray {
  background-color: var(--code-block-background-color);
  box-shadow: 0 2px 0 var(--code-block-background-color), 0 -2px 0 var(--code-block-background-color);
}

.shine-editor .shine-table-wrapper::-webkit-scrollbar {
  -webkit-appearance: none;
  height: 4px;
}

.shine-editor .shine-table-wrapper::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: var(--scroll-thumb-inactive-color);
}

.shine-editor th {
  font-weight: var(--bold-font-weight);
}

.shine-editor table {
  border-top: 1px solid var(--input-border-color);;
}

.shine-editor td, .shine-editor th {
  padding: 2px 10px;
  border-inline-start: 1px solid var(--input-border-color);
  border-inline-end: 1px solid var(--input-border-color);
  border-bottom: 1px solid var(--input-border-color);
}

.shine-editor td.dragging-cell::after, .shine-editor th.dragging-cell::after {
  border-inline-end-color: var(--primary-color);
}

.shine-editor a[data-upnote-tag] {
  background-color: var(--hashtag-background-color);
  padding: 1px 4px;
  border-radius: 4px;
  margin: 0px 1px;
}

.shine-sample-image {
  background-color: transparent;
}

.shine-popup {
  background-color: var(--menu-background-color);
  border-color: var(--separator-color);
  box-shadow: 0px 0px 10px 0px var(--dropdown-shadow-color);
  max-height: 300px;
}

.shine-popup-item {
  color: var(--primary-text-color);
}

.shine-popup-item:hover {
  background-color: var(--menu-hover-color);
}

.shine-popup-item-selected {
  background-color: var(--menu-hover-color);
}

.shine-popup-item-highlight {
  color: var(--primary-text-color);
}

.shine-popup-item-arrow {
  color: #888888;
}

.shine-popup-item .icon-circle {
  font-size: 16px;
}
.shine-popup-item .icon-remove-color {
  font-size: 16px;
}

.shine-popup-item-arrow {
  font-size: 10px;
}

.shine-editor ul li[data-checked='true']::before,
.shine-editor ul li[data-checked='false']::before {
  transform: scale(var(--editor-check-list-icon-zoom));
  top: var(--editor-check-list-top);
}

.shine-editor .shine-collapsible-section {
  border-color: var(--section-border-color);
}

.shine-editor .shine-section-highlight-red {
  background-color: var(--highlight-red);
  border-color: var(--highlight-red) !important;
}

.shine-editor .shine-section-highlight-orange {
  background-color: var(--highlight-orange);
  border-color: var(--highlight-orange) !important;
}

.shine-editor .shine-section-highlight-yellow {
  background-color: var(--highlight-yellow);
  border-color: var(--highlight-yellow) !important;
}

.shine-editor .shine-section-highlight-green {
  background-color: var(--highlight-green);
  border-color: var(--highlight-green) !important;
}

.shine-editor .shine-section-highlight-blue {
  background-color: var(--highlight-blue);
  border-color: var(--highlight-blue) !important;
}

.shine-editor .shine-section-highlight-pink {
  background-color: var(--highlight-pink);
  border-color: var(--highlight-pink) !important;
}

.shine-editor .shine-section-highlight-purple {
  background-color: var(--highlight-purple);
  border-color: var(--highlight-purple) !important;
}

.shine-editor .shine-section-highlight-gray {
  background-color: var(--code-block-background-color);
  border-color: var(--code-block-background-color) !important;
}

.shine-editor .shine-blockquote-red {
  background-color: var(--highlight-red);
  border-color: var(--blockquote-border-red) !important;
}

.shine-editor .shine-blockquote-orange {
  background-color: var(--highlight-orange);
  border-color: var(--blockquote-border-orange) !important;
}

.shine-editor .shine-blockquote-yellow {
  background-color: var(--highlight-yellow);
  border-color: var(--blockquote-border-yellow) !important;
}

.shine-editor .shine-blockquote-green {
  background-color: var(--highlight-green);
  border-color: var(--blockquote-border-green) !important;
}

.shine-editor .shine-blockquote-blue {
  background-color: var(--highlight-blue);
  border-color: var(--blockquote-border-blue) !important;
}

.shine-editor .shine-blockquote-pink {
  background-color: var(--highlight-pink);
  border-color: var(--blockquote-border-pink) !important;
}

.shine-editor .shine-blockquote-purple {
  background-color: var(--highlight-purple);
  border-color: var(--blockquote-border-purple) !important;
}

.shine-editor .shine-blockquote-gray {
  background-color: var(--code-block-background-color);
  border-color: var(--blockquote-border-color) !important;
}

.dark-theme .shine-editor *[class*="shine-section-highlight-"] a {
  color: var(--primary-text-color);
}

.shine-editor .shine-placeholder:before {
  color: var(--placeholder-color);
}

.light-theme .win ::selection,
.light-theme .win *::selection {
  background-color: #bbd4fb;
}

.dark-theme ::selection,
.dark-theme *::selection {
  background-color: #474755;
}
.dark-theme input::selection {
  background-color: #5f5f7e;
}

.shine-editor .shine-iframe-container {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}
.shine-editor iframe {
  background-color: var(--hover-color);
}

.shine-editor iframe[data-upnote-file-preview] {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  border-color: var(--input-border-color);
}

.shine-icon-text-green {
  color: var(--text-green);
}
.shine-icon-text-red {
  color: var(--text-red);
}
.shine-icon-text-orange {
  color: var(--text-orange);
}
.shine-icon-text-blue {
  color: var(--text-blue);
}
.shine-icon-text-yellow {
  color: var(--text-yellow);
}
.shine-icon-text-purple {
  color: var(--text-purple);
}
.shine-icon-text-pink {
  color: var(--text-pink);
}
.shine-icon-text-gray {
  color: var(--text-gray);
}

.shine-icon-highlight-green {
  color: #c3e98d;
}
.shine-icon-highlight-red {
  color: #ed958f;
}
.shine-icon-highlight-orange {
  color: #f7cf9f;
}
.shine-icon-highlight-blue {
  color: #98c7f8;
}
.shine-icon-highlight-yellow {
  color: #f9e496;
}
.shine-icon-highlight-purple {
  color: #c99ae9;
}
.shine-icon-highlight-pink {
  color: #ebabd9;
}
.shine-icon-highlight-gray {
  color: #c2c2c2;
}