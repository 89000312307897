.shine-editor {
    width: 100%;
    min-height: 100%;
    display: block;
    white-space: normal;
    word-wrap: break-word;
    contain: content;
}

.shine-editor u {
    text-underline-offset: 3px;
}

.shine-editor sub {
    vertical-align: sub;
    font-size: 10px;
}

.shine-editor sup {
    vertical-align: super;
    font-size: 10px;
    top: -0.2em;
}

.shine-editor hr {
    height: 1px;
    border: none;
}

.shine-editor img {
    max-width: 100%;
}

.shine-editor img[data-upnote-formula] {
    cursor: pointer;
    vertical-align: middle;
}

.shine-editor ul li[data-checked='true'], .shine-editor ul li[data-checked='false'] {
    list-style: none;
}

[contenteditable=true]:empty:before {
    content: attr(placeholder);
    color: #c0c0c0;
    display: block; /* For Firefox */
}

.shine-editor ul li {
    position: relative;
}

.shine-editor ul li[data-checked='true']::before {
    content: url('./images/tick.svg');
    position: absolute;
    top: 3px;
    margin-inline-start: -24px;
    cursor: pointer;
}

.shine-editor ul li[data-checked='false']::before {
    content: url('./images/untick.svg');
    position: absolute;
    top: 3px;
    margin-inline-start: -24px;
    cursor: pointer;
}

/* ch is a width of a single 0 in current font and size.
 * The margin is set to 2ch because a number list marker
 * usually is `1.` which contains 2 characters. When font size
 * is reduced, need to set the minimum in `px` so the marker
 * is not cut off.
 */
.shine-editor ul, .shine-editor ol {
    padding-inline-start: max(0.55em, 9px);
    margin-top: 0px;
    margin-bottom: 0px;
    margin-inline-end: 0px;
    margin-inline-start: max(2ch, 15px);
  }
  
.shine-editor ol[data-upnote-marker-digit-count="2"] {
    margin-inline-start: 3ch;
}
  
.shine-editor ol[data-upnote-marker-digit-count="3"] {
    margin-inline-start: 4ch;
}
  
.shine-editor ol[data-upnote-marker-digit-count="4"] {
    margin-inline-start: 5ch;
}
  
.shine-editor ol[data-upnote-marker-digit-count="5"] {
    margin-inline-start: 6ch;
}
  
.shine-editor ol[data-upnote-marker-digit-count="6"] {
    margin-inline-start: 7ch;
}
  
.shine-editor ol[data-upnote-marker-digit-count="7"] {
    margin-inline-start: 8ch;
}

.shine-editor blockquote {
    margin: 0px;
    background-color: #eeeeee;
    border-inline-start: #c0c0c0 4px solid;
    border-radius: 4px;
    padding-top: 0px;
    padding-inline-end: 24px;
    padding-bottom: 0px;
    padding-inline-start: 10px;
    position: relative;
}

.shine-editor blockquote::after {
    position: absolute;
    left: unset;
    right: 6px;
    top: 6px;
    width: 16px;
    content: url('./images/more_vertical.svg');
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
}

[dir="rtl"] blockquote::after {
    left: 6px;
    right: unset;
}

.shine-editor .shine-table-wrapper {
    overflow-x: scroll !important;
    overflow-y: hidden;
}

.shine-editor table {
    border-collapse: collapse;
    border-spacing: 0px;
    table-layout: fixed;
    width: max-content;
    margin-inline-end: 20px;
}

.shine-editor tr {
    border: 0px;
}

.shine-editor td, .shine-editor th {
    position: relative;
    min-width: 40px;
    vertical-align: top;
    white-space: normal;
}

.shine-editor td::before, .shine-editor th::before {
    content: url('./images/more_vertical.svg');
    position: absolute;
    padding-top: 3px;
    top: 0px;
    left: unset;
    right: 7px;
    cursor: pointer;
    visibility: hidden;
}

[dir="rtl"] td::before, [dir="rtl"] th::before {
    left: 7px;
    right: unset;
}

.shine-editor td.selected-cell::before, .shine-editor th.selected-cell::before {
    visibility: visible;
}

.shine-editor td::after, .shine-editor th::after {
    content: '';
    position: absolute;
    top: -1px;
    left: unset;
    right: -1px;
    bottom: -1px;
    cursor: col-resize;
    width: 5px;
    border-inline-end: 1px solid transparent;
    padding-top: 3px;
}

[dir="rtl"] td::after, [dir="rtl"] th::after {
    left: -1px;
    right: unset;
}

.shine-editor td.dragging-cell::after, .shine-editor th.dragging-cell::after {
    border-inline-end-color: blue;
    visibility: visible;
    content: '';
}

.shine-editor pre {
    padding-top: 5px;
    padding-inline-end: 24px;
    padding-bottom: 5px;
    padding-inline-start: 10px;
    display: block;
    border-radius: 6px;
    position: relative;
    white-space: pre-wrap;
    direction: ltr;
}

.shine-editor pre::after {
    position: absolute;
    left: unset;
    right: 6px;
    top: 6px;
    width: 16px;
    content: url('./images/more_vertical.svg');
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.shine-editor .shine-code-nowrap {
    overflow-x: auto;
    white-space: pre;
}

.shine-editor .shine-code-nowrap::after {
    display: none;
}

.shine-editor code {
    border-radius: 3px;
    padding: 1px 4px;
}

.shine-editor pre, .shine-editor code {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    font-size: var(--shine-editor-font-size);
}

.shine-editor pre code {
    padding: unset;
    border-radius: unset;
    color: unset;
    background-color: unset;
}

.shine-editor a {
    cursor: pointer;
}

.shine-editor a[data-file-id]::after {
    content: url('./images/file.svg');
    padding: 0px 5px;
}

.shine-editor a[href^='upnote://x-callback-url/openNote?']::after {
    content: url('./images/note.svg');
    padding: 0px 5px;
}

.shine-editor a[href^='upnote://x-callback-url/openNotebook?']::after {
    content: url('./images/notebook.svg');
    padding: 0px 5px;
}

.shine-editor a[href^='upnote://x-callback-url/openFilter?filterId=filterTypeTodo']::after {
    content: url('./images/filter_todo.svg');
    padding: 0px 5px;
}

.shine-editor a[href^='upnote://x-callback-url/openFilter?filterId=filterTypeToday']::after {
    content: url('./images/filter_today.svg');
    padding: 0px 5px;
}

.shine-editor a[href^='upnote://x-callback-url/openFilter?filterId=filterTypeUncategorized']::after {
    content: url('./images/filter_uncategorized.svg');
    padding: 0px 5px;
}
.shine-editor a[href^='upnote://x-callback-url/openFilter?filterId=filterTypeWords']::after {
    content: url('./images/filter_words.svg');
    padding: 0px 5px;
}
.shine-editor a[href^='upnote://x-callback-url/openFilter?filterId=filterTypeUnsynced']::after {
    content: url('./images/filter_unsynced.svg');
    padding: 0px 5px;
}

.shine-editor a[data-upnote-tag] {
    text-decoration: none;
}

.shine-image-resizer {
    content: url('./images/image-resize.svg');;
    border-width: 0px;
    position: absolute;
    user-select: none;
    z-index: 100;
}

[dir="rtl"] .shine-image-resizer {
    transform: rotateY(180deg);
}

.shine-image-option {
    content: url('./images/image-option.svg');
    border-width: 0px;
    position: absolute;
    user-select: none;
    padding: 5px;
    z-index: 100;
}

.shine-image-zoom {
    position: absolute;
    padding: 11px 20px;
    color: white;
    border-radius: 6px;
    background-color: rgba(1, 1, 1, 0.6);
    user-select: none;
    z-index: 100;
}

.shine-popup {
    position: absolute;
    border: 1px solid #dddddd;
    border-radius: 4px;
    background-color: white;
    box-shadow: 0px 0px 10px 0px #bbbbbb;
    max-width: 400px;
    overflow-x: hidden;
    overflow-y: auto;
}

.shine-popup-item {
    margin: 4px;
    padding: 2px 5px;
    color: #444444;
    border-radius: 4px;
    user-select: none;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.shine-popup-item-icon,
.shine-popup-item-title,
.shine-popup-item-arrow {
    margin: 0px 5px;
}

.shine-popup-item-icon {
    width: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #888888;
    flex: 0 0 auto;
}

.shine-popup-item:hover {
    background-color: #f0f0f0;
}

.shine-popup-item[data-disabled="true"]:hover {
    background-color: unset;
}

.shine-popup-item-title {
    flex: 1 1 auto;
    display: block;
    white-space: nowrap;
    overflow:hidden;
    text-overflow: ellipsis;
}

.shine-popup-item-selected {
    background-color: #f0f0f0;
}

.shine-popup-item-highlight {
    color: #111111;
    font-weight: 600;
}

.shine-collapsible-section {
    border: 1px solid #eeeeee;
    border-radius: 6px;
    position: relative;
    padding: 5px 0px;
}


/* Padding left equals to arrow left + arrow width + arrow right */
.shine-section-title-wrapper {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-inline-start: 36px;
    padding-inline-end: 25px;
}

.shine-section-content {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-inline-start: 36px;
    margin-inline-end: 20px;
}

.shine-section-title-wrapper::before {
    position: absolute;
    left: 10px;
    right: unset;
    top: 0;
    bottom: 0;
    width: 16px;
    content: url('./images/arrow_down.svg');
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 100%;
    cursor: pointer;
}

[dir="rtl"] .shine-section-title-wrapper::before {
    right: 10px;
    left: unset;
}

.shine-section-title-wrapper::after {
    position: absolute;
    right: 6px;
    left: unset;
    top: 0;
    bottom: 0;
    width: 16px;
    content: url('./images/more_vertical.svg');
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 100%;
    cursor: pointer;
}

[dir="rtl"] .shine-section-title-wrapper::after {
    right: unset;
    left: 6px;
}

.shine-section-collapsed > :not(.shine-section-title-wrapper) {
    display: none;
}

.shine-section-collapsed .shine-section-title-wrapper::before {
    transform: rotate(-90deg);
}

[dir="rtl"] .shine-section-collapsed .shine-section-title-wrapper::before {
    transform: rotate(90deg);
}

.shine-collapsible-section .shine-section-title-wrapper,
.shine-collapsible-section .shine-section-title,
.shine-collapsible-section .shine-section-content {
    position: relative;
}

.shine-editor .shine-placeholder:before {
    content: attr(data-upnote-placeholder) !important;
    color: #c0c0c0;
    position: absolute;
    height: 100%;
    display: flex;
    align-items: center;
}

.shine-editor .shine-break-all {
    word-break: break-all;
}

.shine-iframe-container {
    overflow: hidden;
    position: relative;
}

.shine-editor iframe {
    top: 0;
    left: 0;
    border: 0;
    outline: none;
    border-radius: 8px;
}
  
.shine-iframe-container iframe {
    position: absolute;
    width: 100% !important;
    height: 100% !important;
}

.shine-editor iframe[data-upnote-file-preview] {
    position: relative;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    border: 1px solid #e8e8e8;
}