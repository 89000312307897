:root {
  --header-height: 44px;
  --list-header-height: 38px;
  --modal-width: 460px;
  --search-height: 32px;
  --primary-text-color: #404040;
  --font: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-size: 14px;
  --bold-font-weight: 500;
  --line-width: 670px;
  --focus-mode-line-width: 670px;
  --line-height: 23px;
  --editor-line-height: 1.86;
  --editor-font-size: 14px;
  --shine-editor-font-size: var(--editor-font-size);
  --paragraph-spacing: 0px;
  --editor-check-list-icon-zoom: 1;
  --editor-check-list-top: 3px;
  --checked-list-text-decoration: none;
  --checked-list-text-color: var(--note-timestamp-color);
  --toggle-width: 2.5rem;
  --toggle-height: 1.25rem;
  --toggle-bg-on: var(--primary-color);
  --toggle-border-on: var(--primary-color);
  --toggle-ring-width: 0px;
  --editor-bottom-padding: 300px;
}

.light-theme {
  color-scheme: light;
  --primary-color: #0078C5;
  --primary-button-hover-color: #0068ac;
  --tooltip-item-selected-color: #a4dafc;
  --tooltip-background-color: rgb(70, 70, 80);
  --note-selected-color: #e4f2fe;
  --dropdown-shadow-color: rgba(0, 0, 0, 0.2);
  --box-shadow-spread: 0px;

  --primary-text-color: #404040;
  --top-bar-icon-color: #666666;
  --top-bar-icon-hover-color: #222222;
  --note-summary-color: #6a6a6a;
  --note-timestamp-color: #a0a0a0;
  --disabled-text-color: #bbbbbb;
  --input-border-color: #d8d8d8;
  --note-info-text-color: #595959;
  --word-count-color: #828282;
  --word-count-border-color: #c0c0c0;
  --section-border-color: #e2e2e2;

  --placeholder-color: #c0c0c0;
  --alert-text-color: #d8616b;

  --app-background-color: #ffffff;
  --modal-background-color: rgba(0, 0, 0, .5);
  --search-input-background-color: #f3f3f3;
  --input-background-color: #f3f3f3;
  --code-background-color: #f5f5f5;
  --code-color: #c44b40;
  --code-block-background-color: #f3f3f3;
  --pin-separator-background-color: #f8f8f8;
  --side-bar-row-selected-background-color: #f4f4f4;
  --setting-row-selected-background-color: var(--side-bar-row-selected-background-color);
  --subscription-condition-background-color: #f6f6f6;
  --premium-product-background-color: white;
  --notes-list-header-color: #f8f8f8;
  --warning-background-color: #fffbe6;
  --windows-bar-background-color: white;
  --windows-bar-modal-background-color: #7f7f7f;
  --windows-bar-text-color: var(--primary-text-color);
  --menu-background-color: white;
  --side-bar-hover-color: #f2f2f2;
  --segmented-active-background-color: #666666;
  --segmented-background-color: var(--app-background-color);
  --table-cell-selected-color: var(--note-selected-color);
  --type-writer-background-color: rgba(0, 0, 0, 0.05);
  --hashtag-background-color: var(--note-selected-color);
  --mark-background-color: #fbef95;
  --active-mark-border-color: #f5cf51;

  --separator-color: #ececec;
  --notes-list-separator-color: #ececec;
  --menu-separator-color: #ececec;
  --hover-color: #f7f7f7;
  --menu-hover-color: #f0f0f0;
  --notification-background-color: #444444;
  --tag-background-color: #ececec;
  --tag-color: #545454;
  --side-bar-icon-color: #6a6a6a;
  --notes-number-color: #a0a0a0;
  --close-icon-color: #aaaaaa;
  --blockquote-border-color: var(--placeholder-color);
  --divider-color: #cacaca;
  --slider-background-color: #d8d8d8;

  --scroll-thumb-inactive-color: #dedede;
  --scroll-thumb-active-color: #c0c0c0;
  --scroll-thumb-hover-color: #aaaaaa;

  --highlight-green: #e7ffcd;
  --highlight-red: #fedede;
  --highlight-orange: #ffe4cb;
  --highlight-blue: #d8ecfd;
  --highlight-yellow: #fbfbc3;
  --highlight-purple: #edddff;
  --highlight-pink: #ffe4fb;
  --highlight-gray: #eeeeee;

  --text-green: #389f08;
  --text-red: #f9241c;
  --text-blue: #2070f7;
  --text-yellow: #d3ad53;
  --text-purple: #8758ff;
  --text-orange: #f98700;
  --text-pink: #E359E1;
  --text-gray: #757575;

  --blockquote-border-red: #F78084;
  --blockquote-border-orange: #FFB36F;
  --blockquote-border-yellow: #E9CC81;
  --blockquote-border-green: #A3C67A;
  --blockquote-border-blue: #68B0EE;
  --blockquote-border-pink: #E79DDE;
  --blockquote-border-purple: #BA93E7;

  --svg-filter: invert(0.15);
  --svg-hover-color: #ffffff;
}

.dark-theme {
  color-scheme: dark;
  --primary-color: #f0f0f0;
  --primary-button-hover-color: #9ed9ff;
  --tooltip-background-color: rgb(70, 70, 80);
  --note-selected-color: #2d2e31;
  --dropdown-shadow-color: rgba(19, 19, 22, 0.4);
  --box-shadow-spread: 5px;

  --primary-text-color: #dedede;
  --note-summary-color: #b0b0b0;
  --top-bar-icon-color: var(--note-summary-color);
  --top-bar-icon-hover-color: #888888;
  --note-timestamp-color: #909090;
  --disabled-text-color: #666666;
  --input-border-color: #505050;
  --note-info-text-color: #b0b0b0;
  --word-count-color: #aaaaaa;
  --word-count-border-color: #888888;
  --section-border-color: #404040;

  --windows-bar-background-color: #28292c;
  --windows-bar-modal-background-color: #101012;
  --windows-bar-text-color: white;
  --menu-background-color: rgb(40, 41, 42);
  --side-bar-hover-color: var(--note-selected-color);
  --segmented-active-background-color: black;
  --segmented-background-color: #333333;
  --table-cell-selected-color: rgb(55, 55, 62);

  --placeholder-color: #777;
  --alert-text-color: #d8616b;

  --app-background-color: #1e1f20;
  --modal-background-color: rgba(0, 0, 0, .6);
  --pin-separator-background-color: rgb(20, 20, 25);
  --input-background-color: rgb(70, 70, 74);
  --code-background-color:rgb(54, 58, 65);
  --code-color: #d88820;
  --code-block-background-color: #323439;
  --search-input-background-color: rgb(51, 51, 58);
  --side-bar-row-selected-background-color: var(--note-selected-color);
  --setting-row-selected-background-color: rgb(56, 56, 60);
  --subscription-condition-background-color: #242424;
  --premium-product-background-color: rgb(52, 52, 55);
  --warning-background-color: #33373B;
  --notes-list-header-color: #27282b;
  --type-writer-background-color: rgba(255, 255, 255, 0.05);
  --hashtag-background-color: #363c44;
  --mark-background-color: #f5cf51;
  --active-mark-border-color: #eeb701;

  --separator-color: rgb(15, 15, 20);
  --notes-list-separator-color: rgb(55, 55, 65);
  --menu-separator-color: rgb(75, 75, 80);
  --hover-color: #333438;
  --menu-hover-color: #34353b;
  --notification-background-color: #363636;
  --tag-background-color: rgb(55, 62, 66);
  --tag-color: var(--primary-text-color);
  --side-bar-icon-color: #909090;
  --notes-number-color: var(--note-timestamp-color);
  --close-icon-color: #aaaaaa;
  --blockquote-border-color: #888888;
  --divider-color: #555555;
  --slider-background-color: #808080;

  --scroll-thumb-inactive-color: #555555;
  --scroll-thumb-active-color: #666;
  --scroll-thumb-hover-color: #777777;

  --highlight-green: #386E53;
  --highlight-red: #844E4E;
  --highlight-orange: #7E552A;
  --highlight-blue: #48657D;
  --highlight-yellow: #78731E;
  --highlight-purple: #58486F;
  --highlight-pink: #713A66;
  --highlight-gray: #4F5356;

  --text-green: #16DBA5;
  --text-red: #FF5959;
  --text-blue: #6DDEFE;
  --text-yellow: #EDE508;
  --text-purple: #C0A2FF;
  --text-orange: #FD9E1F;
  --text-pink: #FF7BFD;
  --text-gray: #9a9a9a;

  --blockquote-border-red: #ae6767;
  --blockquote-border-orange: #a57037;
  --blockquote-border-yellow: #a29a29;
  --blockquote-border-green: #4d9471;
  --blockquote-border-blue: #5f829f;
  --blockquote-border-pink: #9f5492;
  --blockquote-border-purple: #7a6597;

  --svg-filter: invert(0.85);
  --svg-hover-color: #c0c0c0;
}

.blue_sky {
  --primary-color: rgba(0, 120, 197);
  --note-selected-color: #e4f2fe;
  --calendar-color: 'blue';
  --slider-background-color: rgba(0, 120, 197, 0.4);
}

.emerald_theme {
  --primary-color: rgb(16, 132, 144);
  --primary-button-hover-color: rgb(24, 118, 128);
  --note-selected-color: rgb(232, 242, 243);
  --calendar-color: 'teal';
  --slider-background-color: rgba(16, 132, 144, 0.4);
}

.morning_grass {
  --primary-color: rgb(24, 122, 83);
  --primary-button-hover-color: #126141;
  --note-selected-color: rgb(229, 245, 237);
  --calendar-color: 'green';
  --slider-background-color: rgba(24, 122, 83, 0.4);
}

.orange_theme {
  --primary-color: rgb(222, 125, 0);
  --primary-button-hover-color: rgb(201, 114, 26);
  --note-selected-color: rgb(251, 242, 232);
  --calendar-color: 'orange';
  --slider-background-color: rgba(222, 125, 0, 0.4);
}

.pink_theme {
  --primary-color: rgb(215, 103, 132);
  --primary-button-hover-color: rgb(208, 99, 125);
  --note-selected-color: rgb(252, 240, 243);
  --calendar-color: 'pink';
  --slider-background-color: rgba(215, 103, 132, 0.4);
}

.purple_theme {
  --primary-color: rgb(205, 92, 160);
  --primary-button-hover-color: rgb(184, 82, 144);
  --note-selected-color: rgb(250, 238, 245);
  --calendar-color: 'purple';
  --slider-background-color: rgba(205, 92, 160, 0.4);
}

.raspberry {
  --primary-color: rgb(206, 59, 78);
  --primary-button-hover-color: rgb(200, 70, 89);
  --note-selected-color: rgb(251, 237, 239);
  --calendar-color: 'red';
  --slider-background-color: rgba(206, 59, 78, 0.4);
}

.lavender {
  --primary-color: rgb(162, 97, 162);
  --primary-button-hover-color: rgb(126, 75, 126);
  --note-selected-color: rgb(242, 231, 243);
  --calendar-color: 'indigo';
  --slider-background-color: rgba(162, 97, 162, 0.4);
}

.nocturne {
  --primary-color: rgb(74, 146, 254);
  --primary-button-hover-color: rgb(80, 128, 211);
  --calendar-color: 'blue';
  --slider-background-color: rgba(119, 169, 255, 0.4);
}

.dark_emerald_theme {
  --primary-color: rgb(46, 163, 174);
  --primary-button-hover-color: rgb(57, 128, 136);
  --calendar-color: 'teal';
  --slider-background-color: rgba(48, 169, 180, 0.4);
}

.dark_green_theme {
  --primary-color: rgb(47, 179, 150);
  --primary-button-hover-color: rgb(74, 162, 128);
  --calendar-color: 'green';
  --slider-background-color: rgba(47, 179, 150, 0.4);
}

.dark_moonlight {
  --primary-color: rgb(204, 162, 64);
  --primary-button-hover-color: rgb(170, 135, 53);
  --calendar-color: 'yellow';
  --slider-background-color: rgba(204, 162, 64, 0.4);
}

.dark_pink_theme {
  --primary-color: rgb(211, 90, 114);
  --primary-button-hover-color: rgb(202, 86, 109);
  --calendar-color: 'pink';
  --slider-background-color: rgba(223, 94, 120, 0.4);
}

.dark_purple_theme {
  --primary-color: rgb(224, 99, 174);
  --primary-button-hover-color: rgb(178, 90, 143);
  --calendar-color: 'purple';
  --slider-background-color: rgba(224, 99, 174, 0.4);
}

.dark_sunset {
  --primary-color: rgb(226, 75, 75);
  --primary-button-hover-color: rgb(192, 80, 80);
  --calendar-color: 'red';
  --slider-background-color: rgba(226, 75, 75, 0.4);
}

.dark_lavender {
  --primary-color: rgb(191, 128, 223);
  --primary-button-hover-color: rgb(114, 68, 135);
  --calendar-color: 'indigo';
  --slider-background-color: rgba(191, 128, 223, 0.4);
}